import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';
import { SlideResponse } from '../types/slide.types';

const SLIDES = '/slides-v2';

type CreateSlideBody = Pick<
  SlideResponse,
  'title' | 'status' | 'projectData' | 'dynamicData'
>;

export interface UpdateSlideBody
  extends Partial<
    Pick<SlideResponse, 'title' | 'status' | 'projectData' | 'dynamicData'>
  > {
  id: string;
}

export interface UpdateSlideResponse {
  _id: string;
}

export type GetSlidesResponses = SlideResponse[];

export const useCreateSlide = (opt?: MutOptions<SlideResponse>) =>
  useMutation<SlideResponse, ApiServiceErr, CreateSlideBody>(async (data) => {
    const response = await axiosApi.post(SLIDES, data);
    return response.data;
  }, opt);

export const useUpdateSlide = (opt?: MutOptions<UpdateSlideResponse>) =>
  useMutation<UpdateSlideResponse, ApiServiceErr, UpdateSlideBody>(
    async (data) => {
      const response = await axiosApi.put(`${SLIDES}/${data.id}`, data);
      return response.data;
    },
    opt,
  );

export const useGetAllSlides = () =>
  useQuery<GetSlidesResponses, ApiServiceErr>(['slides-v2/all'], async () => {
    const response = await axiosApi.get(`${SLIDES}/all/detailed`);
    return response.data;
  });

export const useGetSlideById = (id: string) =>
  useQuery<SlideResponse, ApiServiceErr>(
    ['slides-v2/{byId}', id],
    async ({ queryKey }) => {
      const response = await axiosApi.get(`${SLIDES}/${queryKey[1]}`);
      return response.data;
    },
  );

export const useDeleteSlide = (opt?: MutOptions<UpdateSlideResponse>) =>
  useMutation<UpdateSlideResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`${SLIDES}/${data.id}`);
    return response.data;
  }, opt);

export const useGetPaginateSlide = ({
  limit,
  page,
  sortby,
  order,
  device,
  search,
}: PaginateOptions & { search?: string; device?: string }) =>
  useQuery<PagingResponse<GetSlidesResponses>, ApiServiceErr>(
    [
      '/slides-v2?limit=${limit}&page=${page}&sortBy=${sortBy}&asc=${asc}&device=${device}&search=${search}',
      limit,
      page,
      sortby,
      order,
      device,
      search,
    ],
    async () => {
      const response = await axiosApi.get(`${SLIDES}`, {
        params: { limit, page, sortby, order, device, search },
      });
      return response.data;
    },
  );

export const useLockSlideById = () =>
  useMutation<SlideResponse, ApiServiceErr, { isEditing: boolean; id: string }>(
    async ({ isEditing, id }) => {
      const response = await axiosApi.put(
        `${SLIDES}/edit/slide?id=${id}&isEditing=${isEditing}`,
      );
      return response.data;
    },
  );
